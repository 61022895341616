function serviceSelectedFromTooltip(service, date, chartType) {
    let obj = {
        service: service,
        date: date,
        chartType
    }
    if(window.billingComponentReference && window.location.href.indexOf('billing') > -1){
        window.billingComponentReference.zone.run(() => { window.billingComponentReference.callGetUsageTypesforServiceFunction(obj); }); 
    }
    if( window.bandwidthComponentReference && window.location.href.indexOf('bandwidth') > -1){
        window.bandwidthComponentReference.zone.run(() => { window.bandwidthComponentReference.callGetUsageTypesforServiceFunction(obj); }); 
    }
    if( window.wasabiComponentReference && window.location.href.indexOf('wasabi') > -1){
        window.wasabiComponentReference.zone.run(() => { window.wasabiComponentReference.callGetStorageTypesforServiceFunction(obj); }); 
    }
    
}

function stopCloudMapperDiagramSpinner(){
    if( window.CloudMapperMainComponentReference && window.location.href.indexOf('cloud-mapper') > -1){
        window.CloudMapperMainComponentReference.zone.run(() => { window.CloudMapperMainComponentReference.callStopCloudMapperDiagramSpinnerFunction(); }); 
    }
}